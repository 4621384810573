import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import LayoutComponent from "../LayoutComponent"
import { URL } from "../../config/settings"
import AsideBlock from "../../blocks/articles-page/aside.block"

import { Seo } from "../seo"

export interface ArticleTempProps {
  data:any;
  path: any;
}
function ArticleTemp(props: { data: any; path: any} ) {
  const {
    Title,
    Content,
    Image,
    PublicationDate,
    author_of_article,
    category,
    Slug,
    keywords,
  } = props.data.allStrapiArticle.nodes[0]

  const [content, setContent] = useState()
  useEffect(() => {
    setContent(Content)
    if (props.path !== "/") {
      const HEADER = document.querySelector(".content-header")
      const HEADER_MOBIL = document.querySelector(".header-mobile")
      HEADER?.classList.add("swap-header")
      HEADER_MOBIL?.classList.add("change-backgorund")
    }
  }, [])
  let created = PublicationDate.split(" ");
  
  {console.log(Slug);}
  return (
    <LayoutComponent>
      <Seo
        title={`Super Sonic Design INC | ${Title}`}
        image={Image[0].url}
        description={Content}
        author={`Super Sonic Design INC`}
        url={`https://supersonicdesign.com/articles/` + {Slug}}
        keywords={[keywords]} />
      <div className="container container-only-article">
        <div className="row">
          <div className="col-lg-9">
            <h3 className="container-only-article__head-title">ニュース・コンテンツ</h3>
            <div className="container  container-only-article__card">
              <img
                className="container-only-article__banner"
                // src={`${URL}${Image[0].url}`}
                src={`${URL}${Image[0].url}`}
                alt={Title} />

              <h3 className="container-only-article__title">{Title}</h3>
              <div className="container-only-article__icons">
                <div className="tags">
                  {category === null ? "" :  
                    <Link
                      to={`/articles/${category.SlugCat}`}
                      className={`btn-categorie btn-categorie--${category.NameCategory}`}
                    >
                      {category.NameCategory}
                    </Link>
                  }
                 {author_of_article === null ? "" : <span>By {author_of_article.NameAuthor}</span>}
                  
                </div>
              <span>{created[2] + "年" + created[0] + "月" + created[1] + "日"}</span>
              </div>
              <div className="container-only-article__content-blog">
                <p dangerouslySetInnerHTML={{ __html: content }}></p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <AsideBlock />
          </div>
        </div>
      </div>
    </LayoutComponent>
  )
}

export default ArticleTemp
export const query = graphql`
  query($slug: String!) {
    allStrapiArticle(filter: { Slug: { eq: $slug } }) {
      nodes {
        author_of_article {
          NameAuthor
        }
        PublicationDate(formatString: "MM DD YYYY")
        Title
        Slug
        Content
        keywords
        category {
          NameCategory
          SlugCat
        }
        Image {
          url
        }
      }
    }
  }
`
